import { VoyagePriceSummaryDetail } from 'api-hooks/voyage/model';
import { Expose, Type } from 'class-transformer';
import { ImageClass } from 'common/repositories/common.model';
import { Tracing } from 'trace_events';

export enum PaymentMethodEnum {
  VirtualAccount = 'virtual_account',
  CounterPayment = 'counter_payment',
  SelfCounter = 'self_counter',
}

export enum BookingStatusEnum {
  CREATED = 'created',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
  RESCHEDULING = 'rescheduling',
}
export enum RefundStatusEnum {
  PENDING = 'pending',
  REJECTED = 'rejected',
  REFUNDED = 'refunded',
}

export enum TicketTypeEnum {
  PASSENGER = 'Passenger',
  VEHICLE = 'Vehicle',
}

export enum VehicleIdentifierTypeEnum {
  PoliceNumber = 'police_number',
}

export enum PassengerIdentifierTypeEnum {
  NationalId = 'national_id',
  Passport = 'passport',
  DriverLicense = 'driver_license',
}

export enum PassengerTypeEnum {
  Adult = 'adult',
  Infant = 'infant',
}

export class SummaryModel {
  @Expose({ name: 'previous_booking_price' })
  previousBookingPrice: number;

  @Expose({ name: 'admin_fee' })
  adminFee: number;

  @Expose({ name: 'subtotal_1' })
  subtotal1: number;

  @Expose({ name: 'reschedule_fee' })
  rescheduleFee: number;

  @Expose({ name: 'subtotal_2' })
  subtotal2: number;

  @Expose({ name: 'new_booking_price' })
  newBookingPrice: number;

  @Expose({ name: 'total_pay' })
  totalPay: number;
}

export class CountObject {
  name: string;
  qty: number;
}

export class VehicleCountObject {
  name: string;
  qty: number;

  @Expose({ name: 'max_height' })
  maxHeight: string | null;
}
export class BookingVehicleType {
  id: string;
  name: string;
  description: string;
  accommodation: number;

  @Expose({ name: 'max_height' })
  maxHeight: string | null;
}

export class BookingVehicleContentType {
  id: string;
  name: string;
}

export class PassengerFacility {
  type: string;
  variant: string;
  code: string;
}

export class BookingPassenger {
  id: string;
  name: string;
  type: string;
  @Expose({ name: 'identifier_code' })
  identifierCode: string;
  @Expose({ name: 'seat_number' })
  seatnumber: string;
  facility: PassengerFacility;
  @Expose({ name: 'is_insurance' })
  isInsurance: boolean;
  @Type(() => Number)
  price: number;
  @Type(() => Number)
  @Expose({ name: 'insurance_price' })
  insurancePrice: number;
  description: string;
}

export class BookingDriver {
  id: string;
  name: string;
}

export class BookingPassengerType {
  id: string;
  name: string;
}

export class BookingVehicle {
  id: string;
  @Type(() => BookingVehicleType)
  @Expose({ name: 'vehicle_type' })
  vehicleType: BookingVehicleType;

  @Expose({ name: 'identifier_code' })
  identifierCode: string;

  @Type(() => BookingDriver)
  driver: BookingDriver;

  expedition: string;
  description: string;
  condition: string;

  @Type(() => ImageClass)
  documents?: ImageClass[];

  @Type(() => BookingPassengerType)
  passengers: BookingPassengerType[];

  @Expose({ name: 'is_insurance' })
  isInsurance: boolean;

  @Type(() => Number)
  price: number;

  @Expose({ name: 'insurance_price' })
  @Type(() => Number)
  insurancePrice: number;

  @Type(() => BookingVehicleContentType)
  @Expose({ name: 'vehicle_content_type' })
  vehicleContentType: BookingVehicleContentType;
}

export class BookingFacility {
  id: string;
  code: string;
  name: string;
  variant: string;

  @Type(() => BookingPassengerType)
  passengers: BookingPassengerType[];

  @Type(() => Number)
  price: number;
}

export class BookingPrice {
  name: string;
  type: string;
  qty: number;

  @Type(() => Number)
  price: number;

  @Expose({ name: 'total_price' })
  totalPrice: number;
}
export class DateTimeModel {
  date: string | null;
  time: string | null;
}
export class BookingLiteModel {
  id: string;

  @Expose({ name: 'from_harbor' })
  fromHarbor: string;

  @Expose({ name: 'to_harbor' })
  toHarbor: string;

  @Expose({ name: 'from_city' })
  fromCity: string;

  @Expose({ name: 'to_city' })
  toCity: string;

  ship: string;

  @Expose({ name: 'total_price' })
  totalPrice: number;

  @Expose({ name: 'departure_at' })
  @Type(() => DateTimeModel)
  departureAt: DateTimeModel;

  @Expose({ name: 'arrived_at' })
  @Type(() => DateTimeModel)
  arrivedAt: DateTimeModel;

  @Expose({ name: 'delayed_departure_at' })
  @Type(() => DateTimeModel)
  delayedDepartureAt: DateTimeModel;

  @Expose({ name: 'departure_tz' })
  departureTz: string;

  @Expose({ name: 'arrived_tz' })
  arrivedTz: string;

  @Expose({ name: 'ended_at' })
  @Type(() => Date)
  endedAt: Date;

  @Expose({ name: 'passenger_adult_count' })
  passengerAdultCount: number;
  @Expose({ name: 'passenger_infant_count' })
  passengerInfantCount: number;

  @Type(() => VehicleCountObject)
  @Expose({ name: 'vehicle_count' })
  vehicleCount: VehicleCountObject[];

  @Expose({ name: 'facility_count' })
  @Type(() => CountObject)
  facilityCount: CountObject[];

  @Type(() => BookingPassenger)
  passengers: BookingPassenger[];

  @Type(() => BookingVehicle)
  vehicles: BookingVehicle[];

  @Type(() => BookingFacility)
  facilities: BookingFacility[];

  status: BookingStatusEnum;

  @Expose({ name: 'expired_at' })
  @Type(() => Date)
  expiredAt: Date;

  @Expose({ name: 'refund_status' })
  refundStatus: RefundStatusEnum | null;

  @Expose({ name: 'refund_rejected_reason' })
  refundRejectedReason: string | null;
  code: string;

  @Expose({ name: 'voyage_code' })
  voyageCode: string;
}

export class BookingPassengerFacility {
  type: string;
  variant: Tracing;
  code: string;
}

export class BookingDetailPassenger {
  id: string;
  name: string;
  type: string;
  @Expose({ name: 'identifier_code' })
  identifierCode: string;

  @Expose({ name: 'seat_number' })
  seatNumber: string;

  @Expose({ name: 'insurance_price' })
  @Type(() => Number)
  insurancePrice: number;

  @Expose({ name: 'is_insurance' })
  isInsurance: number;

  @Type(() => Number)
  price: number;

  @Expose({ name: 'is_facilitated' })
  isFacilitated: boolean;

  @Type(() => BookingPassengerFacility)
  facility: BookingPassengerFacility;
}

export class BookingDetailFacility {
  id: string;
  @Expose({ name: 'voyage_id' })
  voyageId: string;
  @Expose({ name: 'booking_id' })
  bookingId: string;
  @Expose({ name: 'voyage_facility_type_id' })
  voyageFacilityTypeId: string;

  @Expose({ name: 'sales_invoice_item_id' })
  salesInvoiceItemId: string;

  code: string;
  status: string;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'updated_at' })
  @Type(() => Date)
  updatedAt: Date;
}

export class BookingModel {
  id: string;

  @Type(() => BookingDetailPassenger)
  passengers: BookingDetailPassenger[];

  @Type(() => BookingVehicle)
  vehicles: BookingVehicle[];

  @Type(() => BookingFacility)
  facilities: BookingFacility[];

  @Expose({ name: 'total_price' })
  @Type(() => Number)
  totalPrice: number;
}

export class BookingHarbor {
  @Expose({ name: 'city_name' })
  cityName: string;
  name: string;
}

export class BookingVoyage {
  id: string;
  code: string;

  @Expose({ name: 'arrived_at' })
  @Type(() => DateTimeModel)
  arrivedAt: DateTimeModel;

  @Expose({ name: 'delayed_arrived_at' })
  @Type(() => DateTimeModel)
  delayArrivalAt: DateTimeModel;

  @Expose({ name: 'arrived_tz' })
  arrivedTz: string;

  @Expose({ name: 'departure_at' })
  @Type(() => DateTimeModel)
  departureAt: DateTimeModel;

  @Expose({ name: 'delayed_departure_at' })
  @Type(() => DateTimeModel)
  delayDepartureAt: DateTimeModel;

  @Expose({ name: 'departure_tz' })
  departureTz: string;

  @Expose({ name: 'from_harbor' })
  @Type(() => BookingHarbor)
  fromHarbor: BookingHarbor;

  @Expose({ name: 'to_harbor' })
  @Type(() => BookingHarbor)
  toHarbor: BookingHarbor;

  @Expose({ name: 'vehicle_room_qty' })
  vehicleRoomQty: number;
}

export class BookingFormPassenger {
  id: string;
  type: string;

  @Expose({ name: 'insurance_price' })
  insurancePrice: string;

  @Expose({ name: 'is_insurance_required' })
  isInsuranceRequired: boolean;
}

export class BookingVehicleTypeContent {
  id: string;
  name: string;
}

export class BookingFormVehicleType extends BookingVehicleType {
  code: string;

  @Expose({ name: 'vehicle_type_contents' })
  @Type(() => BookingVehicleTypeContent)
  vehicleTypeContents: BookingVehicleTypeContent[];
}

export class BookingFormVehicle {
  id: string;

  @Expose({ name: 'vehicle_type' })
  @Type(() => BookingFormVehicleType)
  vehicleType: BookingFormVehicleType;

  @Expose({ name: 'insurance_price' })
  insurancePrice: string;

  @Expose({ name: 'is_insurance_required' })
  isInsuranceRequired: boolean;
}

export class BookingFormFacilityType {
  id: string;
  name: string;
  variant: string;

  @Expose({ name: 'bed_qty' })
  bedQty: number | null;

  capacity: number | null;
}

export class BookingFormFacility {
  id: string;

  @Type(() => ImageClass)
  images: ImageClass[];

  @Expose({ name: 'facility_type' })
  @Type(() => BookingFormFacilityType)
  facilityType: BookingFormFacilityType;

  @Expose({ name: 'voyage_facility_type_id' })
  voyageFacilityTypeId: string;

  @Expose({ name: 'adult_qty' })
  adultQty: number;
  @Expose({ name: 'infant_qty' })
  infantQty: number;
}

export class BookingShowFormModel {
  id: string;
  code: string;
  status: BookingStatusEnum;
  type: string;

  @Expose({ name: 'is_vehicle_type_upstair' })
  isVehicleUpstair: boolean;

  @Expose({ name: 'expired_at' })
  @Type(() => Date)
  expiredAt: Date;

  @Expose({ name: 'total_passengers' })
  totalPassengers: number;

  @Expose({ name: 'total_vehicles' })
  totalVehicles: number;

  @Type(() => BookingVoyage)
  voyage: BookingVoyage;

  @Expose({ name: 'price_summary' })
  @Type(() => VoyagePriceSummaryDetail)
  priceSummary: VoyagePriceSummaryDetail;

  @Type(() => BookingFormPassenger)
  passengers: BookingFormPassenger[];

  @Type(() => BookingFormVehicle)
  vehicles: BookingFormVehicle[];

  @Type(() => BookingFormFacility)
  facilities: BookingFormFacility[];
}

// Payment Model

export class BookingPaymentPriceSummaryItemModel {
  name: string;
  qty: number;
  price: string | null;

  @Expose({ name: 'total_price' })
  totalPrice: number;

  @Expose({ name: 'max_height' })
  maxHeight: string;
}

export class BookingPaymentPriceSummaryModel {
  @Type(() => BookingPaymentPriceSummaryItemModel)
  passengers: BookingPaymentPriceSummaryItemModel[];

  @Type(() => BookingPaymentPriceSummaryItemModel)
  vehicles: BookingPaymentPriceSummaryItemModel[];

  @Type(() => BookingPaymentPriceSummaryItemModel)
  facilities: BookingPaymentPriceSummaryItemModel[];

  @Expose({ name: 'total_price' })
  totalPrice: number;

  @Expose({ name: 'admin_fee' })
  adminFee: number;

  @Expose({ name: 'harbor_fee' })
  harborFee: number;

  @Expose({ name: 'total_price_with_fee' })
  totalPriceWithFee: number;
}

export class BookingPaymentPaymentModel {
  id: string;
  type: string;
  name: string;
  amount: string;
  number: string;

  @Type(() => ImageClass)
  images: ImageClass[];

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;
}

export class BookingPaymentPassengerModel {
  id: string;
  name: string;
  type: string;

  @Type(() => PassengerFacility)
  facility: PassengerFacility | null;

  @Expose({ name: 'identifier_code' })
  identifierCode: string;

  @Expose({ name: 'seat_number' })
  seatNumber: string | null;
}

export class BookingPaymentVehicleModel {
  condition: any | null;
  dsecription: string | null;
  document: any | null;
  expedition: string;
  id: string;

  @Expose({ name: 'identifier_code' })
  identifierCode: string;

  @Expose({ name: 'vehicle_type' })
  @Type(() => BookingVehicleType)
  vehicleType: BookingVehicleType;

  @Expose({ name: 'vehicle_content_type' })
  @Type(() => BookingVehicleContentType)
  vehicleContentType: BookingVehicleContentType;

  @Type(() => BookingPassengerType)
  passengers: BookingPassengerType[];

  @Type(() => BookingPassengerType)
  driver: BookingPassengerType | null;
}

export class BookingBookingModel {
  id: string;
  code: string;

  @Type(() => BookingPaymentPaymentModel)
  payment: BookingPaymentPaymentModel | null;

  @Expose({ name: 'credit_settlement_id' })
  creditSettlementId: string;

  @Type(() => BookingVoyage)
  voyage: BookingVoyage;

  @Expose({ name: 'price_summary' })
  @Type(() => BookingPaymentPriceSummaryModel)
  priceSummary: BookingPaymentPriceSummaryModel;

  @Expose({ name: 'total_passengers' })
  totalPassengers: number;

  @Expose({ name: 'total_vehicles' })
  totalVehicles: number;

  @Expose({ name: 'expired_at' })
  @Type(() => Date)
  expiredAt: Date;

  status: BookingStatusEnum;

  @Type(() => BookingPaymentPassengerModel)
  passengers: BookingPaymentPassengerModel[];

  @Type(() => BookingPaymentVehicleModel)
  vehicles: BookingPaymentVehicleModel[];
}

export class PaymentImageClass extends ImageClass {
  @Expose({ name: 'is_enabled' })
  isEnabled: boolean;
}

export class BookingPaymentMethodModel {
  id: string;
  type: string;
  name: string;

  @Expose({ name: 'payment_user_fee' })
  paymentUserFee: number;

  @Type(() => ImageClass)
  images: ImageClass[];

  @Expose({ name: 'is_enabled' })
  isEnabled: boolean;
}

export class BookingPaymentModel {
  @Type(() => BookingBookingModel)
  booking: BookingBookingModel;

  @Expose({ name: 'payment_methods' })
  @Type(() => BookingPaymentMethodModel)
  paymentMethods: BookingPaymentMethodModel[];

  @Expose({ name: 'reschedule_summary' })
  @Type(() => SummaryModel)
  rescheduleSummary: SummaryModel | null;
}

export class BookingTicketPriceSummaryModel {
  price: number;
  @Expose({ name: 'insurance_price' })
  insurancePrice: number;
  @Expose({ name: 'facility_price' })
  facilityPrice: number;

  @Expose({ name: 'admin_user' })
  adminUser: string;

  total: number;
}

export class BookingTicketModel {
  id: string;
  type: string;
  name: string;
  gender: string;
  code: string;
  age: number;

  @Expose({ name: 'identifier_code' })
  identifierCode: string;

  @Expose({ name: 'identifier_type' })
  identifierType: string;

  @Expose({ name: 'from_harbor' })
  fromHarbor: string;

  @Expose({ name: 'to_harbor' })
  toHarbor: string;

  @Expose({ name: 'from_city' })
  fromCity: string;

  @Expose({ name: 'to_city' })
  toCity: string;

  @Expose({ name: 'departure_at' })
  @Type(() => DateTimeModel)
  departureAt: DateTimeModel;

  @Expose({ name: 'arrived_at' })
  @Type(() => Date)
  arrivedAt: Date;

  @Expose({ name: 'delayed_departure_at' })
  @Type(() => DateTimeModel)
  delayedDepartureAt: DateTimeModel;

  @Expose({ name: 'departure_tz' })
  departureTz: string;

  @Expose({ name: 'arrived_tz' })
  arrivedTz: string;

  @Expose({ name: 'departure_tz_offset' })
  departureTzOffset: string;

  @Expose({ name: 'seat_number' })
  seatNumber: string;

  facility: string | null;

  @Expose({ name: 'is_facilitated' })
  isFacilitated: boolean;

  @Expose({ name: 'price_summary' })
  @Type(() => BookingTicketPriceSummaryModel)
  priceSummary: BookingTicketPriceSummaryModel;

  @Expose({ name: 'vehicle_type' })
  vehicleType: string;

  @Expose({ name: 'vehicle_max_height_meter' })
  vehicleMaxHeightMeter: string;

  @Expose({ name: 'vehicle_type_description' })
  vehicleTypeDescription: string;

  @Expose({ name: 'vehicle_number' })
  vehicleNumber: string;

  driver: string | null;

  @Expose({ name: 'vehicle_description' })
  vehicleDescription: string | null;
}

export class BookingCountModel {
  active: number;
  pending: number;
}

export class HarborModel {
  @Expose({ name: 'name' })
  name: string;

  @Expose({ name: 'city_name' })
  cityName: string;
}

export class VoyageModel {
  id: string;
  code: string;

  @Expose({ name: 'from_harbor' })
  @Type(() => HarborModel)
  fromHarbor: HarborModel;

  @Expose({ name: 'to_harbor' })
  @Type(() => HarborModel)
  toHarbor: HarborModel;

  @Expose({ name: 'departure_at' })
  @Type(() => DateTimeModel)
  departureAt: DateTimeModel;

  @Expose({ name: 'delayed_departure_at' })
  @Type(() => DateTimeModel)
  delayedDepartureAt: DateTimeModel;

  @Expose({ name: 'departure_tz' })
  departureTz: string;

  @Expose({ name: 'arrived_at' })
  @Type(() => DateTimeModel)
  arrivedAt: DateTimeModel;

  @Expose({ name: 'arrived_tz' })
  arrivedTz: string;
}
export class RefundModel {
  @Expose({ name: 'booking_code' })
  bookingCode: string;

  @Expose({ name: 'refund_status' })
  refundStatus: RefundStatusEnum | null;

  @Type(() => VoyageModel)
  voyage: VoyageModel;

  @Expose({ name: 'total_passengers' })
  totalPassenger: number;

  @Expose({ name: 'total_vehicles' })
  totalVehicles: number;

  @Expose({ name: 'total_amount' })
  totalAmount: number;
}

export class PaymentFeeModel {
  @Expose({ name: 'payment_fee' })
  paymentFee: number;
}
// Input

export class getBookingsInput {
  params: {
    type?: 'active' | 'pending' | 'history' | any;
    page?: number;
    filter?: string;
    sort?: string;
    limit?: number;
  };
}

export class getBookingInput {
  bookingId: string;
}

export class BookingMutationInput {
  voyageCode: string;
  passengers: {
    type: string;
    qty: number;
  }[];
  vehicles: {
    vehicleTypeid: string;
    qty: number;
  }[];
  facilities: {
    voyageFacilityTypeId: string;
    qty: number;
  }[];
  type: number;
}

export type BookingOrdererFormTypeUpdateInput = {
  name: string;
  email: string;
  phoneNumber: string;
};

export type BookingPassengerFormTypeUpdateInput = {
  id: string;
  type: string;
  identifierCode: string | null;
  identifierType: string | null;
  name: string;
  age: number;
  gender: string;
  phoneNumber: string | null;
  isInsurance: boolean;
  save: boolean;
};

export type BookingVehicleFormTypeUpdateInput = {
  id: string;
  vehicleTypeId: string;
  identifierCode: string;
  identifierType: string;
  content: string | null;
  expedition: string | null;
  condition: string | null;
  description: string | null;
  documents:
    | {
        file: string;
      }[]
    | null;
  passengerIds: string[];
  driverId: string | null;
  isInsurance: boolean;
  save: boolean;
};

export type BookingFacilityFormTypeUpdateInput = {
  id: string;
  voyageFacilityTypeId: string;
  passengerIds: string[];
};

export type BookingFormTypeUpdateInput = {
  bookingId: string;
  body: {
    orderer: BookingOrdererFormTypeUpdateInput;
    passengers: BookingPassengerFormTypeUpdateInput[];
    vehicles: BookingVehicleFormTypeUpdateInput[];
    facilities: BookingFacilityFormTypeUpdateInput[];
  };
};

export type BookingCreatePaymentMutationInput = {
  creditSettlementId: string;
  body: {
    paymentMethodId: string;
  };
};

export type BookingCancelInput = {
  bookingId: string;
};

export type RefundMutationInput = {
  body: {
    bookingId: string;
    reason: string;
    financialInstitution: string;
    accountNumber: string;
    accountName: string;
    method: string;
  };
};

export type PrintBookingTiketInput = {
  bookingId: string;
};
